export const colors = {
  darkBlue: "#101928",
  middleBlue : "#2C4E80",
  gray: "#f2f2f2",
  grayDark: "#52565e",
  grayMiddle : '#8996a0',
  orange : "#FF6500",
  grayBlack : "#2b2b2b",
  bej : "#e2e1db"
};
