import React, { useEffect, useState } from "react";
import {
  Typography,
  Layout,
  Button,
  Row,
  Image,
  Col,
  Flex,
  Card,
  Avatar,
  message,
  FloatButton,
  Skeleton,
  TabsProps,
  Tabs,
  Tag,
} from "antd";
import {
  DashboardOutlined,
  ShopOutlined,
  ExperimentFilled,
  ProductOutlined,
  TeamOutlined,
  CopyOutlined,
  GiftFilled,
  GiftOutlined,
  CalendarOutlined,
  ThunderboltOutlined,
  SettingOutlined,
  HeartFilled,
  NotificationOutlined,
  LineChartOutlined,
  InteractionOutlined,
  ApartmentOutlined,
  ArrowUpOutlined,
  UserOutlined,
  UploadOutlined,
  QuestionOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";

import styled, { createGlobalStyle } from "styled-components";
import { motion, useIsPresent, usePresence, Variants } from "framer-motion";
import { Link, useNavigate, useParams } from "react-router-dom";
import Navbar from "./components/Navbar";
import MobileNavbar from "../../layout/MobileNavbar";
import Footer from "./components/Footer";
import { useAuthContext } from "../../context/AuthContext";
import {
  useClickedProductByVisiter,
  useGetLinkInfoForVisiter,
} from "./api/query";
import { colors } from "../../assets/colors";
import { useMediaQueryContext } from "../../context/MediaQueryContext";
import Meta from "antd/es/card/Meta";
import { TYPE_OF_SELLER } from "../../assets/constant";
const { Text, Title } = Typography;
const { Header, Content } = Layout;

export default function Dashboard() {
  const { username } = useParams();
  const { isMobile, isTablet, isDesktop } = useMediaQueryContext();
  const [loadingClick, setLoadingClick] = useState(false);
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();

  const clickedProductMutation = useClickedProductByVisiter();

  const {
    data: profileInfoForVisiterResponseData,
    isError: isError,
    isLoading: isLoadingProfileInfo,
  } = useGetLinkInfoForVisiter(username);

  const [itemsProduct, setItemsProduct] = useState(
    profileInfoForVisiterResponseData?.activeAdsInfo || []
  );
  const [itemsPlace, setItemsPlace] = useState([]);

  useEffect(() => {
    if (profileInfoForVisiterResponseData) {
      setItemsProduct(
        profileInfoForVisiterResponseData?.activeAdsInfo?.filter(
          (item) => item?.campaign?.campaignType === TYPE_OF_SELLER.PRODUCT
        )
      );
      setItemsPlace(
        profileInfoForVisiterResponseData?.activeAdsInfo?.filter(
          (item) => item?.campaign?.campaignType === TYPE_OF_SELLER.PLACE
        )
      );
    }
  }, [profileInfoForVisiterResponseData]);

  const goToProductPage = async (item, event) => {
    event.preventDefault();
    event.stopPropagation();
    const payload = {
      influencerNickname: username,
      campaignId: item.campaign.id,
    };

    if (loadingClick) return 0;
    setLoadingClick(true);

    try {
      messageApi.open({
        type: "loading",
        content: "Yönlendiriliyor..",
        duration: 0,
      });
      await clickedProductMutation.mutateAsync(payload);
      const externalUrl = item?.campaign?.campaignProduct?.productUrl;
      if (externalUrl) {
        window.location.href = externalUrl;
      }
    } catch (error) {
      message.open({
        type: "error",
        content: error.response.data.message,
      });
    } finally {
      setLoadingClick(false);
      messageApi.destroy();
    }
  };

  useEffect(() => {
    if (profileInfoForVisiterResponseData) {
      if (profileInfoForVisiterResponseData.isFound === false) {
        message.error("Profil bilgisi bulunamadı veya hata oluştu.");
        navigate("/NotFound");
      }
    }
  }, [isError, profileInfoForVisiterResponseData, navigate]);

  return (
    <DesktopView
      campaignsForVisiterResponseData={profileInfoForVisiterResponseData}
      isLoadingProfileInfo={isLoadingProfileInfo}
      itemsProduct={itemsProduct}
      itemsPlace={itemsPlace}
      username={username}
      goToProductPage={goToProductPage}
      contextHolder={contextHolder}
      isMobile={isMobile}
    />
  );
}

const DesktopView = ({
  campaignsForVisiterResponseData,
  isLoadingProfileInfo,
  username,
  goToProductPage,
  contextHolder,
  isMobile,
  itemsProduct,
  itemsPlace,
}) => {
  const onChange = (key: string) => {
    console.log(key);
  };

  const items: TabsProps["items"] = [
    itemsProduct?.length > 0 && {
      key: "1",
      label: (
        <Row>
          <ProductOutlined style={{ color: colors.darkBlue }} />
          <Text style={{ fontWeight: "bold", color: colors.darkBlue }}>
            Ürünlerim
          </Text>
        </Row>
      ),
      children: (
        <ProductsPart
          campaignsForVisiterResponseData={itemsProduct}
          goToProductPage={goToProductPage}
        ></ProductsPart>
      ),
    },
    itemsPlace?.length > 0 && {
      key: "2",
      label: (
        <Flex>
          <ExperimentFilled />
          <Text style={{ fontWeight: "bold" }}>Mekanlar</Text>
        </Flex>
      ),
      children: (
        <ProductsPart
          campaignsForVisiterResponseData={itemsPlace}
          goToProductPage={goToProductPage}
        ></ProductsPart>
      ),
    },
    
  ];

  const siteName = "https://linklio.co/";
  const copyToLink = () => {
    navigator.clipboard
      .writeText(
        siteName + campaignsForVisiterResponseData?.influencerProfile?.nickName
      )
      .then(() => {
        message.success("Link kopyalandı!");
      })
      .catch(() => {
        message.error("Kopyalama başarısız oldu!");
      });
  };
  return (
    <Row
      style={{
        width: "100%",
        height: "100%",
        backgroundColor: "white",
        color: "white",
        minWidth: "300px",
        overflow: "scroll",
      }}
      justify={"center"}
    >
      {contextHolder}

      <Row
        style={{
          width: "90%",
          margin: "0 auto",
          minWidth: 300,
          maxHeight: 70,
          display: "flex",
          alignItems: "center",
          overflow: "hidden",
          position: "fixed",
          backgroundColor: "white",
          zIndex: 5,
        }}
        justify={"space-between"}
      >
        <Col>
          <Image
            src={"/logo.svg"}
            style={{ height: "100%", maxHeight: "80px" }}
            preview={false}
          ></Image>
        </Col>

        <Col>
          <Button
            style={{
              backgroundColor: colors.grayBlack,
              color: colors.bej,
              borderRadius: 0,
            }}
          >
            Keşfet <ThunderboltOutlined />
          </Button>
        </Col>
      </Row>

      <Row
        style={{
          width: "95%",
          marginInline: "auto",
          minWidth: "300px",
          marginTop: "3.2rem",
          minHeight: "80vh",
          display: "flex",
        }}
        align={"top"}
      >
        <Col xs={24} sm={24} md={24} lg={8} xl={8} style={{ width: "100%" }}>
          <Row
            style={{
              width: "100%",
              marginInline: "auto",
              marginTop: "2rem",
            }}
          >
            {isMobile ? (
              <Row
                style={{ width: "100%", marginInline: "auto" }}
                justify={"space-between"}
              >
                <Col xs={6} sm={4} md={6} lg={6} xl={6}>
                  {isLoadingProfileInfo ? (
                    <Skeleton.Avatar active size={80} />
                  ) : (
                    <Avatar
                      size={80}
                      style={{ borderRadius: 0 }}
                      src={
                        campaignsForVisiterResponseData?.influencerProfile
                          ?.profilePhoto
                      }
                    />
                  )}
                </Col>

                <Col
                  xs={17}
                  sm={19}
                  md={9}
                  lg={9}
                  xl={9}
                  style={{ alignContent: "center" }}
                >
                  <Row style={{ width: "100%", marginBottom: "0.7rem" }}>
                    <Text
                      style={{
                        fontWeight: "bold",
                        fontSize: "1rem",
                        marginBlock: "auto",
                      }}
                    >
                      {
                        campaignsForVisiterResponseData?.influencerProfile
                          ?.nickName
                      }
                    </Text>
                  </Row>

                  <Row style={{ width: "100%" }} justify={"space-between"}>
                    <Col>
                      <Tag
                        style={{
                          fontWeight: "bold",
                          fontSize: "1rem",
                          marginBlock: "auto",
                          backgroundColor: colors.bej,
                          padding: "0.35rem",
                          borderRadius: 0,
                          cursor: "pointer",
                        }}
                      >
                        100 <HeartFilled />
                      </Tag>
                    </Col>

                    <Col>
                      <Button
                        style={{
                          backgroundColor: colors.grayBlack,
                          borderRadius: 0,
                        }}
                        icon={<UploadOutlined style={{ color: colors.bej }} />}
                        onClick={() => copyToLink()}
                      ></Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            ) : (
              <>
                <Row
                  style={{ width: "100%", marginInline: "auto" }}
                  justify={"center"}
                >
                  {isLoadingProfileInfo ? (
                    <Skeleton.Avatar active size={200} />
                  ) : (
                    <Avatar
                      size={200}
                      style={{ borderRadius: 0 }}
                      src={
                        campaignsForVisiterResponseData?.influencerProfile
                          ?.profilePhoto
                      }
                    />
                  )}
                </Row>
                <Row
                  style={{ width: "100%", marginTop: "2rem" }}
                  justify={"center"}
                >
                  <Text style={{ fontWeight: "bold", fontSize: "1.2rem" }}>
                    {
                      campaignsForVisiterResponseData?.influencerProfile
                        ?.nickName
                    }
                  </Text>
                </Row>
                <Row
                  style={{ width: "100%", marginTop: "1rem" }}
                  justify={"center"}
                >
                  <Col>
                    <Tag
                      style={{
                        fontWeight: "bold",
                        fontSize: "1rem",
                        marginBlock: "auto",
                        backgroundColor: colors.bej,
                        padding: "0.35rem",
                        borderRadius: 0,
                        cursor: "pointer",
                      }}
                    >
                      100 <HeartFilled />
                    </Tag>
                  </Col>

                  <Col>
                    <Button
                      style={{
                        backgroundColor: colors.grayBlack,
                        borderRadius: 0,
                      }}
                      icon={<UploadOutlined style={{ color: colors.bej }} />}
                      onClick={() => copyToLink()}
                    ></Button>
                  </Col>
                </Row>
              </>
            )}
          </Row>
        </Col>

        <Col
          xs={24}
          sm={24}
          md={24}
          lg={16}
          xl={16}
          style={{
            marginBottom: "2rem",
            marginTop: "1rem",
            marginInline: "auto",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            minHeight: "80vh",
          }}
        >
          <Tabs
            defaultActiveKey="1"
            items={items}
            onChange={onChange}
            style={{
              marginInline: "10px",
              flexGrow: 1,
              minHeight: "300px",
            }}
          />
        </Col>
      </Row>
    </Row>
  );
};

const ProductsPart = ({ campaignsForVisiterResponseData, goToProductPage }) => {
  return (
    <Row
      style={{ width: "100%", marginTop: "1rem", marginInline: "auto" }}
      gutter={[30, 30]}
    >
      {campaignsForVisiterResponseData
        ?.sort((a, b) => a.position - b.position)
        .map((item, key) => (
          <Col xs={24} sm={24} md={12} lg={12} xl={8}>
            <ProductCard goToProductPage={goToProductPage} item={item} />
          </Col>
        ))}
    </Row>
  );
};

const CouponPart = ({ campaignsForVisiterResponseData, goToProductPage }) => {
  return (
    <Row
      style={{ width: "100%", marginTop: "1rem", marginInline: "auto" }}
      gutter={[30, 30]}
    >
      {campaignsForVisiterResponseData?.activeAdsInfo
        ?.sort((a, b) => a.position - b.position)
        .map((item, key) => (
          <Col xs={24} sm={24} md={12} lg={12} xl={8}>
            <CouponCard goToProductPage={goToProductPage} item={item} />
          </Col>
        ))}
    </Row>
  );
};

const StyledFlex = styled.div`
  position: relative; // Important for positioning the FloatButton
  width: 100%;
  max-height: 27vh;
  height: 100%;
  overflow: hidden;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  cursor: pointer;
  transition: transform 0.3s ease; // Smooth transition for scaling
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);

  &:hover {
    z-index: 1; // Bring the hovered card to the front
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3); // Darker shadow on hover
  }
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); // Semi-transparent overlay
  opacity: 0;
  transition: opacity 0.3s ease;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;

  ${StyledFlex}:hover & {
    opacity: 0.1; // Change opacity on hover for better visibility
  }
`;

const StyledFloatButton = styled(FloatButton)`
  position: absolute;
  bottom: 5px;
  right: 5px; // Positioning it at the bottom right
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  visibility: hidden; // Hide initially
  opacity: 0; // Hide initially
  transition: visibility 0s, opacity 0.3s ease; // Smooth transition for visibility and opacity

  ${StyledFlex}:hover & {
    visibility: visible; // Make it visible on hover
    opacity: 1; // Ensure it appears fully visible
  }
`;

const ProductCard = ({ goToProductPage, item }) => {
  const [touchStartTime, setTouchStartTime] = useState(0);
  const [isTouching, setIsTouching] = useState(false);

  const handleTouchStart = () => {
    setTouchStartTime(Date.now());
    setIsTouching(true);
  };

  const handleTouchMove = () => {
    if (isTouching) {
      setIsTouching(false);
    }
  };

  const handleTouchEnd = (item, e) => {
    const touchDuration = Date.now() - touchStartTime;

    if (touchDuration < 200 && isTouching) {
      goToProductPage(item, e);
    }

    setIsTouching(false);
  };

  const handleFloatButtonClick = (e) => {
    e.stopPropagation(); // Prevent the click from triggering the card's click event
    // Perform the desired action here (e.g., show details)
    console.log("FloatButton clicked, show more details for:", item);
  };

  return (
    <StyledFlex
      onClick={(e) => goToProductPage(item, e)}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={(e) => handleTouchEnd(item, e)}
    >
      {" "}
      <img
        alt="example"
        style={{ objectFit: "cover", width: "100%", height: "80%" }}
        src={item?.campaign?.campaignProduct?.productImageUrls[0]}
      />
      <Row
        style={{ zIndex: 3, overflow: "hidden", height: "15%" }}
        align={"middle"}
      >
        <Text
          style={{
            fontWeight: "bold",
            paddingInline: "7px",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
        >
          {item?.campaign?.campaignProduct?.productName}
        </Text>
      </Row>
      <Overlay />
      {/* <StyledFloatButton
        icon={<QuestionOutlined />}
        onClick={handleFloatButtonClick} // Assign the click handler
      /> */}
    </StyledFlex>
  );
};

const CouponCard = ({ goToProductPage, item }) => {
  const copyCouponCode = () => {
    navigator.clipboard
      .writeText("HEDIYE-200")
      .then(() => {
        message.success("Kupon kopyalandı!");
      })
      .catch(() => {
        message.error("Kopyalama başarısız oldu!");
      });
  };

  return (
    <Card
      style={{ width: "100%" }}
      cover={
        <img
          alt="example"
          style={{ maxHeight: "250px", objectFit: "cover" }}
          src={item?.campaign?.campaignProduct?.productImageUrls[0]}
        />
      }
    >
      <Row style={{ width: "100%" }} justify={"space-between"}>
        <Text style={{ fontWeight: "bold", fontSize: "15px" }}>NIKE</Text>
      </Row>
      <Row style={{ width: "100%", marginTop: "1rem" }}>
        <Button
          icon={<CopyOutlined />}
          onClick={() => copyCouponCode()}
          style={{
            width: "100%",
            overflow: "hidden",
          }}
        >
          HEDIYE-200
        </Button>
      </Row>
    </Card>
  );
};
